<template>
  <div>
    <c-table
      ref="table"
      :title="`단위작업: ${hazard.sopName}`"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="hazard.heavyWorkHazards"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="heaMuscleSopHazardId"
      @innerBtnClicked="innerBtnClicked"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="saveData"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveHazard"
            @btnCallback="saveHazardCallback" />
          <c-btn v-if="hazard.heavyWorkHazards.length>0" label="삭제" icon="delete" 
              @btnClicked="deleteHazard" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'muscleHazardCheck',
  props: {
    hazard: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSopStepCd: '',  // 진행상태
        processCd: '',  // 공정코드
        processName: '',  // 공정명
        mdmSopId: '',  // 안전작업 표준 일련번호
        sopName: '',  // 세부공정(작업) 명
        heaInvestigationTypeCd: '',  // 조사구분
        investigationDate: '',  // 조사일시
        deptCd: '',  // 조사자 부서
        userId: '',  // 조사자 id
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        heavyWorks: [], // 부담작업
        heavyWorkIncludes: [], // 부담작업 결과 O
        heavyWorkHazards: [], // 유해요인
        workers: [], // 작업자
        situations: [], // 상황조사
        results: [], // 결과표
        imprs: [], // 개선
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'HEA_MUSCLE_HAZARD',
        taskKey: '',
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'heaMuscleHeavyWorkId' },
          { index: 1, colName: 'heaMuscleHeavyWorkId' },
        ],
        columns: [
          {
            name: 'heavyWorkName',
            field: 'heavyWorkName',
            label: '부담작업No',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'detailJobContents',
            field: 'detailJobContents',
            label: '작업내용',
            align: 'left',
            style: 'width:120px',
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', tooltip: '유해요인 추가' },
            ],
            sortable: false,
          },
          {
            required: true,
            name: 'hazardName',
            field: 'hazardName',
            label: '유해요인',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'hazardCause',
            field: 'hazardCause',
            label: '유해요인의 발생원인 또는 노출특징',
            align: 'left',
            style: 'width:400px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
        ],
      },
      editable: true,
      isSave: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 100) + 'px' : '500px'
    },
    saveData() {
      let _items = [];
      if (this.hazard.heavyWorkHazards && this.hazard.heavyWorkHazards.length > 0) {
        this.$_.forEach(this.hazard.heavyWorkHazards, item => {
          if (item.editFlag === 'C' && item.hazardName) {
            // 신규등록인데 유해요인을 등록한 경우
            // 쿼리에서 editFlag C인 데이터에 등록자 id를 따로 넣지 않음으로
            this.$set(item, 'regUserId', this.$store.getters.user.userId)
            _items.push(item)
          }
          if (item.editFlag === 'U') {
            // 수정한 경우 (별다르게 체크하지 않음)
            _items.push(item)
          }
        })
      }
      return _items;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSopHazard.list.url
      this.saveUrl = transactionConfig.hea.muscleSystem.muscleSopHazard.save.url
      this.deleteUrl = transactionConfig.hea.muscleSystem.muscleSopHazard.delete.url
      // code setting
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaMuscleSopId: this.popupParam.heaMuscleSopId
      }
      this.$http.request((_result) => {
        console.log('_result', _result.data)
        this.$set(this.hazard, 'heavyWorkHazards', _result.data)
      },);
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props) {
      this.hazard.heavyWorkHazards.splice(props.rowIndex, 0, {
        heaMuscleSopHazardId: uid(),  // 근골격계 단위작업 부담작업 유해요인 일련번호
        heaMuscleHeavyWorkId: props.row.heaMuscleHeavyWorkId,  // 근골격계 부담작업 일련번호
        heaMuscleSopId: props.row.heaMuscleSopId,  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: props.row.heaInvestigationPlanId,  // 근골격계 조사계획 일련번호
        heaHeavyWorkNo: props.row.heaHeavyWorkNo,  // 부담작업no
        heavyWorkName: props.row.heavyWorkName,  // 부담작업명
        detailJobContents: props.row.detailJobContents,
        hazardName: '',  // 유해요인
        hazardCause: '',  // 유해요인의 발생원인 또는 노출특징
        remark: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    deleteHazard() {
      let selectData = this.$refs['table'].selected;
        if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '선택된 항목이 없습니다.',
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '삭제하시겠습니까? '+' ['+selectData.length+' 건]',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.deleteUrl
              this.$http.type = 'DELETE';
              this.$http.param = {
                data: Object.values(selectData)
              }
              this.$http.request(() => {
                this.$_.forEach(selectData, item => {
                  this.hazard.heavyWorkHazards = this.$_.reject(this.hazard.heavyWorkHazards, { heaMuscleSopHazardId: item.heaMuscleSopHazardId })
                })
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
    },
    saveHazard() {      
      if (this.$comm.validTable(this.grid.columns, this.saveData)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveHazardCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>