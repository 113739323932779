var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: `단위작업: ${_vm.hazard.sopName}`,
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.hazard.heavyWorkHazards,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "heaMuscleSopHazardId",
          },
          on: { innerBtnClicked: _vm.innerBtnClicked },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled,
                        expression: "editable && !disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.saveData,
                      mappingType: "PUT",
                      label: "저장",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveHazard,
                      btnCallback: _vm.saveHazardCallback,
                    },
                  }),
                  _vm.hazard.heavyWorkHazards.length > 0
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "delete" },
                        on: { btnClicked: _vm.deleteHazard },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }